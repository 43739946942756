

















import Vue from "vue";
import AppAside from "./components/app-aside.vue";
import AppHeader from "./components/app-header.vue";

export default Vue.extend({
  name: "LayoutIndex",
  components: {
    AppAside,
    AppHeader
  }
});

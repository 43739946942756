






















import Vue from "vue";
import { getUserInfo, logout } from "@/api/user";

export default Vue.extend({
  name: "AppHeader",
  data() {
    return {
      userInfo: {}
    };
  },
  created() {
    this.loadUserInfo();
  },
  computed: {
    breadcrumb() {
      return this.$route.meta.breadcrumb;
    }
  },
  methods: {
    async loadUserInfo() {
      const { data } = await getUserInfo();
      this.userInfo = data.content;
    },
    logout() {
      this.$confirm("确认退出吗？", "退出提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const { data } = await logout();
          if (data.state !== 1) {
            this.$message.error(data.message);
          } else {
            this.$store.commit("setUser", null);

            // 跳转到登录页面
            this.$router.push({
              name: "login"
            });

            this.$message({
              type: "success",
              message: "退出成功!"
            });
          }
        })
        .catch(() => {
          // 取消执行这里
          this.$message({
            type: "info",
            message: "已取消退出"
          });
        });
    }
  }
});

























































import Vue from "vue";

export default Vue.extend({
  name: "AppAside",
  methods: {
    handleOpen(key: string, keyPath: string): void {
      console.log(key, keyPath);
    },

    handleClose(key: string, keyPath: string): void {
      console.log(key, keyPath);
    }
  }
});
